import RemoteImage from '@atoms/RemoteImage';
import { getStore } from '@store/storeUtils';
import { View, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import logReasonTap from './analyticsReasonTile';
import styles from './styles';

function reasonTapped(reasonsProps) {
  const { allReasons, selectedReason, setSelectedReason, setDisableContinue, setWithdrawalReason } = reasonsProps;
  const changedReasons = allReasons.map(item => {
    const changedItem = item;
    if (selectedReason.reason.toUpperCase() === item.reason.toUpperCase()) {
      setWithdrawalReason({ ...changedItem, selected: true });
      return { ...changedItem, selected: true };
    }
    return { ...changedItem, selected: false };
  });
  setSelectedReason(changedReasons);

  setDisableContinue(false);
}

function ReasonsSwitch(props) {
  const { reason: selectedReason, setSelectedReason, setDisableContinue, key, allReasons, source } = props;
  const { setWithdrawalReason, selectedAccount, validatedReferralCode } = getStore();
  const { amplitudeAccountName } = selectedAccount;
  return (
    <TouchableOpacity
      key={key}
      testID={`btnReasonSwitch_${selectedReason.id}`}
      sentry-label="btnReasonSwitch"
      style={styles.reasonSwitch(selectedReason)}
      aria-label="reason"
      onPress={() => {
        logReasonTap(selectedReason, source, amplitudeAccountName, validatedReferralCode);
        reasonTapped({ allReasons, selectedReason, setSelectedReason, setDisableContinue, setWithdrawalReason });
      }}>
      <RemoteImage uri={selectedReason.imageUrl} width={40} height={40} />
      <View style={styles.reasonContainer}>
        <Text
          testID={`txtSelectedReason_${selectedReason.id}`}
          alignSelf="center"
          justifyContent="center"
          noOfLines={2}
          variant="xsm-normal"
          overflow="hidden"
          textAlign="center">
          {selectedReason.reason}
        </Text>
      </View>
      <View style={styles.selectedDotStyle(selectedReason)} />
    </TouchableOpacity>
  );
}

ReasonsSwitch.propTypes = {
  source: PropTypes.string,
  key: PropTypes.number,
  reason: PropTypes.shape({
    id: PropTypes.number,
    reason: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
  selectedReason: PropTypes.shape({
    reason: PropTypes.string,
    imageUrl: PropTypes.string,
  }).isRequired,
  setSelectedReason: PropTypes.func,
  setDisableContinue: PropTypes.func,
  allReasons: PropTypes.arrayOf(
    PropTypes.shape({
      reason: PropTypes.shape({
        reason: PropTypes.string,
        imageUrl: PropTypes.string,
      }),
    }),
  ),
};
ReasonsSwitch.defaultProps = {
  source: '',
  key: 0,
  reason: {
    id: 0,
    reason: '',
    imageUrl: '',
  },
  setSelectedReason: () => {},
  setDisableContinue: () => {},
  allReasons: [{ reason: { reason: '', imageUrl: '' } }],
};
export default ReasonsSwitch;
