import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  reasonGrid: {
    alignItems: 'center',
    alignSelf: 'center',
    columnGap: 14,
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    justifyContent: 'center',
    marginHorizontal: 5,
  },
  reasonParent: {
    paddingBottom: 150,
    // borderColor: 'red',
    // borderWidth: 1,
    // eslint-disable-next-line react-native/sort-styles
    alignContent: 'center',
    alignSelf: 'stretch',
    flex: 1,
  },
});
export default styles;
