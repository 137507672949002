import colors from '@theme/colors';
import { StyleSheet } from 'react-native';
import { getSelectedReasonBackgroundColor, getSelectedReasonBorderColor } from './reasonSwitchUtils';

const styles = StyleSheet.create({
  reasonContainer: {
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    paddingVertical: 5,
  },
  reasonSwitch: selectedReason => ({
    alignItems: 'center',
    alignSelf: 'stretch',
    borderColor: selectedReason.selected ? colors.error.rose : colors.disabled.smoke,
    borderRadius: 12,
    borderWidth: selectedReason.selected ? 2 : 1,
    flexDirection: 'column',
    height: 90,
    // marginHorizontal: 5,
    // flex: 2,
    marginVertical: 5,
    overflow: 'hidden',
    paddingHorizontal: 5,
    paddingVertical: 10,
    width: '30%',
  }),
  selectedDotStyle: selectedReason => ({
    width: 8,
    height: 8,
    borderRadius: 8,
    borderWidth: 1,
    top: 5,
    right: 5,
    position: 'absolute',
    borderColor: getSelectedReasonBorderColor(selectedReason),
    backgroundColor: getSelectedReasonBackgroundColor(selectedReason),
  }),
});
export default styles;
