import { fetchLogs, fetchMeUseQuery } from '@api/useQueryCalls';
import AutoWithdrawal from '@atoms/AutoWithdrawal';
import SDModal from '@atoms/SDModal';
import AccountList from '@molecules/AccountList';
import ReferralInput from '@molecules/ReferralInput';
import WithdrawlSlider from '@molecules/WithdrawlSlider';
import { shouldRenderHomeOnSuccess, shouldShowError } from '@organisms/HomeTab/homeUtils';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import { useQueryClient } from '@tanstack/react-query';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { getAmplitudeYesNo, getAmplitudeKeyValue } from '@utils/utils';
import { View, useToast } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import shouldFireOnFocus from './meComponentUtils';

export function logHomePage(meApiData, meAmplitudeContext) {
  const e1 = AMPLITUDE_CONSTANTS.home_page_detail;
  AmplitudeHelper.logEvent(e1.name, {
    [e1.attributes.lock_period]: getAmplitudeYesNo(meApiData?.isInLockPeriod),
    ...getAmplitudeKeyValue(e1.name, meAmplitudeContext),
  });
}

function MeComponent() {
  const { t } = useTranslation('common');
  const toast = useToast();
  const navigation = useNavigation();
  const { isError, error, isSuccess, data: meApiData, refetch, isLoading } = fetchMeUseQuery();
  const { isSuccess: logApiSuccess, data: logData, refetch: logApiRefetch } = fetchLogs();
  const [showSDModal, setShowSDModal] = useState(null);
  const {
    selectedAccount,
    setAccounts,
    setCanShowReferral,
    setEmployeeId,
    setIsInLockPeriod,
    setReferralApplicable,
    setStatus,
    setUserId,
    setSelectedAccount,
    setInitialData,
    setEmployerId,
    setBPAccounts,
    setSelectedBPAccounts,
    loginData,
    otpData,
    setBPPhone,
    setBankForTransfer,
    setIsSuspended,
    setHeaderText,
    setReferralHeaderText,
    setAppliedReferral,
    setTxnSuccessReferral,
    setMeAmplitudeContext,
    meAmplitudeContext,
    setAutoDisburseData,
  } = getStore();

  const { mobileNumber } = loginData || '';
  const { userName } = otpData || '';
  const isFocused = useIsFocused();
  const queryClient = useQueryClient();

  useEffect(() => {
    shouldFireOnFocus(isFocused, queryClient, refetch, setBankForTransfer);
  }, [isFocused]);

  useEffect(() => {
    shouldShowError(isError, error, toast, t, navigation, setInitialData);
  }, [isError, error]);

  useEffect(() => {
    if (meApiData && isSuccess) {
      logHomePage(meApiData, meAmplitudeContext);
      setShowSDModal(meApiData?.isInLockPeriod);
      shouldRenderHomeOnSuccess({
        meApiData,
        isSuccess,
        setAccounts,
        setSelectedAccount,
        setCanShowReferral,
        setEmployeeId,
        setIsInLockPeriod,
        setReferralApplicable,
        setStatus,
        setUserId,
        setEmployerId,
        mobileNumber,
        setBPAccounts,
        setSelectedBPAccounts,
        userName,
        setBPPhone,
        setIsSuspended,
        setHeaderText,
        setReferralHeaderText,
        setAppliedReferral,
        setTxnSuccessReferral,
        setAutoDisburseData,
      });
    }
  }, [meApiData, isSuccess]);

  useEffect(() => {
    if (showSDModal) {
      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.sd_period_started.name);
    }
  }, [showSDModal]);

  useEffect(() => {
    logApiRefetch();
  }, [isFocused]);

  useEffect(() => {
    if (logApiSuccess && logData) setMeAmplitudeContext(logData);
  }, [logApiSuccess, logData]);

  return (
    <View>
      <View testID="lstAccounts" style={appStyles.bgColor(colors.secondary.orchid)}>
        <AccountList isLoading={isLoading} />
      </View>
      <AutoWithdrawal />
      <ReferralInput
        isLoading={isLoading}
        selectedAccount={selectedAccount}
        pageSource={AMPLITUDE_EVENT_SOURCES.home_page}
      />
      <WithdrawlSlider isLoading={isLoading} />

      {showSDModal ? <SDModal showSDModal={showSDModal} /> : null}
    </View>
  );
}

export default MeComponent;
