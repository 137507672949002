import { queryClient } from '@api/clientProvider';
import RightArrow from '@atoms/RightArrow';
import ToastError from '@atoms/ToastError';
import ToastSuccess from '@atoms/ToastSuccess';
import ToastWarning from '@atoms/ToastWarning';
import config from '@config';
import { Ionicons } from '@expo/vector-icons';
import { isValidNumberWithDot } from '@molecules/SliderView/sliderUtils';
import ScreenNames from '@navigation/navigationConstants';
import { CommonActions } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import { Text, View } from 'native-base';
import React from 'react';
import { Linking, TouchableOpacity } from 'react-native';
import AmplitudeHelper from './analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from './analytics/constants';
import packageJson from '../../package.json';
import {
  LOGIN_INFO_SHEET_CONTENT,
  LOGIN_INFO_SHEET_KEYS,
  PHONE_ENROLLMENT_STATUS,
  REDIRECTION_TYPES,
  UPDATE_TYPE,
} from '../constants';

export const removeNonNumeric = num => num?.toString().replace(/[^0-9]/g, '');

export const formatPhoneNumber = (phoneNumber, countryCode = '62') => {
  if (phoneNumber === '') return '';
  const firstTwoChar = phoneNumber.substring(0, 2);
  const firstChar = phoneNumber.substring(0, 1);
  if (firstTwoChar === '62') {
    return `${countryCode}${phoneNumber.substring(2, phoneNumber.length)}`;
  }
  if (firstChar === '0') {
    return `${countryCode}${phoneNumber.substring(1, phoneNumber.length)}`;
  }
  return `${countryCode}${phoneNumber}`;
};

export const camelToSnake = str => {
  let result = str;
  result = str.charAt(0).toLowerCase() + str.slice(1);
  result = str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  return result;
};

export const spaceToSnake = str => str.replace(/[ -]/g, '_');

export const returnBottomSheetContent = (key, content, phoneNumber) => {
  if (key === LOGIN_INFO_SHEET_KEYS['GG-OTP-07'] || key === LOGIN_INFO_SHEET_KEYS.RESEND_OTP)
    return {
      ...content,
      title: content?.title,
      phoneNumber,
      description: content?.description || null,
      description2: content?.description2 || null,
    };

  return {
    ...content,
    title: content?.title,
    phoneNumber: '',
    description: content?.description || null,
    description2: content?.description2 || null,
  };
};

export function gotBackToLogin(navigation) {
  navigation.dispatch(
    CommonActions.reset({
      index: 0,
      routes: [{ name: 'Login' }],
    }),
  );
}

export function resetToHomeNavStack(navigation) {
  navigation.dispatch(
    CommonActions.reset({
      index: 0,
      routes: [{ name: ScreenNames.HOME }],
    }),
  );
}

export function resetToNavStack(navigation, screenName) {
  navigation.dispatch(
    CommonActions.reset({
      index: 0,
      routes: [{ name: ScreenNames.HOME }, { name: screenName }],
    }),
  );
}
export function goBackToHome(navigation, invalidateHomePageQueries = true) {
  if (invalidateHomePageQueries) {
    return queryClient
      .invalidateQueries({ queryKey: ['me_api', 'topProducts_api', 'marketingBanner_api'] })
      .finally(() => resetToHomeNavStack(navigation));
  }
  return resetToHomeNavStack(navigation);
}

export function serverError(errorCodes, toast, t, navigation, clearState) {
  if (errorCodes && errorCodes.length > 0 && (errorCodes[0] === 'GG-GEN-05' || errorCodes[0] === 'GG-REF-TKN-04')) {
    clearState();
    gotBackToLogin(navigation);
  }
  toast.show({
    render: () => <ToastError data={t(`errorCodes.${errorCodes[0]}`)} />,
    placement: 'top',
    duration: 5000,
  });
}

export function showErrorBottomSheet(setIsLoadingSMS, setIsLoadingWhatsapp, phoneNumber, error, setInfoModal) {
  const authAmplitude = AMPLITUDE_CONSTANTS.authentication;
  setIsLoadingSMS(false);
  setIsLoadingWhatsapp(false);
  const e = authAmplitude.otp_failed;
  AmplitudeHelper.logEvent(e.name, {
    [e.attributes.phone_number]: formatPhoneNumber(phoneNumber),
    [e.attributes.failure_message]: AMPLITUDE_EVENT_SOURCES.login_error[error || 'GG-500'],
    [e.attributes.source]: AMPLITUDE_EVENT_SOURCES.login_page_source.login_page,
  });

  if (!error) {
    return setInfoModal({
      visible: true,
      data: returnBottomSheetContent(
        LOGIN_INFO_SHEET_KEYS['GG-500'],
        LOGIN_INFO_SHEET_CONTENT[LOGIN_INFO_SHEET_KEYS['GG-500']],
        formatPhoneNumber(phoneNumber),
      ),
      errorCode: LOGIN_INFO_SHEET_KEYS['GG-500'],
    });
  }

  return setInfoModal({
    visible: true,
    data: returnBottomSheetContent(
      LOGIN_INFO_SHEET_KEYS[error],
      LOGIN_INFO_SHEET_CONTENT[LOGIN_INFO_SHEET_KEYS[error]],
      formatPhoneNumber(phoneNumber),
    ),
    errorCode: LOGIN_INFO_SHEET_KEYS[error],
  });
}

export const singleFeatureEnabled = (featureList, featureCode) => {
  const findIndex = featureList.findIndex(e => e.code === featureCode);
  if (findIndex !== -1) {
    return {
      feature: featureList[findIndex],
      show: featureList[findIndex].show,
    };
  }
  return {
    feature: null,
    show: false,
  };
};

const multipleFeatureEnabled = (featureList, featureCodes) => {
  const resultObj = {};
  featureCodes.forEach(o => {
    resultObj[o] = singleFeatureEnabled(featureList, o);
  });
  return resultObj;
};

export const isFeatureEnabled = (featureList, featureCode) => {
  if (Array.isArray(featureCode)) return multipleFeatureEnabled(featureList, featureCode);
  return singleFeatureEnabled(featureList, featureCode);
};

export function validateInternetReachableState(setConnectionStatus) {
  return state => {
    if (!state.isConnected) {
      setConnectionStatus(true);
    } else if (state.isConnected) {
      setConnectionStatus(false);
    }
  };
}
export const PERMISSION_LIMIT = 3;

export function maskPhoneNumber(inputString) {
  const { length } = inputString;
  if (length <= 4) {
    return inputString;
  }

  const maskedPart = '*'.repeat(length - 4);
  const visiblePart = inputString.slice(length - 4);
  return maskedPart + visiblePart;
}

export const getUserBanksFromQuery = data => {
  // let defaultBank = null;
  const userBanks = [];
  const commonUserReadStatus = true;
  if (data.length > 0) {
    // defaultBank = {
    //   bankAccountNo: data[0]?.bankAccountNo ?? '',
    //   bankName: data[0]?.bank?.bankName ?? '',
    //   beneficiaryName: data[0]?.beneficiaryName ?? '',
    // };

    data.forEach(e => {
      // if (!e.isUserRead) commonUserReadStatus = false;

      const newBank = {
        accountNumber: e?.accountNumber ?? '',
        beneficiaryName: e?.beneficiaryName ?? '',
        bankName: e?.bank?.bankName ?? '',
        id: e?.id ?? '',
        // isUserRead: e.isUserRead,
        nickName: e?.nickname ?? '',
        status: e?.status ?? '',
      };
      userBanks.push(newBank);
    });
  }
  return {
    // defaultBank,
    userBanks,
    commonUserReadStatus,
  };
};

export const formatUnderScoreLowerCase = keyWord => {
  if (!keyWord || keyWord.length === 0) {
    return keyWord;
  }
  return keyWord?.toLowerCase()?.replace(/ /g, '_') ?? '';
};

export function logErrorOnCrash(error, source) {
  const errorEvent = AMPLITUDE_CONSTANTS.errorPage.error_page_restart_button_clicked;
  const amplitudeProperties = {
    [errorEvent.attributes.event_triggers]: formatUnderScoreLowerCase(error?.message || error?.stack),
  };
  if (source !== null) {
    amplitudeProperties.source = source;
  }

  AmplitudeHelper.logEvent(errorEvent.name, amplitudeProperties);
}

export const numberWithDots = x => x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

export const isNotPoints = selectedTxnAccount => selectedTxnAccount?.accountTypeId !== 1;

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}
export function getSelectedAccounts(selectedAcc, allAccounts, setSelectedTxnAccount) {
  if (isEmptyObject(selectedAcc)) return setSelectedTxnAccount(allAccounts.filter(e => e.accountTypeId === 0)[0]);
  return selectedAcc;
}

// export function validatePin(code) {
//   if (code.length !== 6) return false;
//   let counter = 0;
//   let sequenceCounter = 1;
//   const uniqueObj = {};
//   let setAscDesc = -1;

//   for (let i = 0; i < code.length; i += 1) {
//     // all same pin validation
//     if (!uniqueObj[code[i]]) {
//       uniqueObj[code[i]] = true;
//       counter = 1;
//     } else if (uniqueObj[code[i]]) counter += 1;

//     if (counter === code.length) return false;

//     // sequence check
//     if (i + 1 < code.length) {
//       if (i === 0) {
//         if (code[i + 1] > code[i]) setAscDesc = 1;
//         else setAscDesc = -1;
//       }

//       if (setAscDesc === 1) {
//         if (code[i + 1] - code[i] === 1) sequenceCounter += 1;
//       } else if (setAscDesc === -1) {
//         if (code[i] - code[i + 1] === 1) sequenceCounter += 1;
//       } else sequenceCounter = 1;
//     }

//     if (sequenceCounter === code.length) return false;
//   }

//   return true;
// }

export function validatePin(code) {
  if (code.length !== 6) return false;

  let isSamePin = true;
  let isSequence = true;

  for (let i = 0; i < code.length - 1; i += 1) {
    // All same pin validation
    if (code[i] !== code[i + 1]) {
      isSamePin = false;
    }

    // Sequence check
    const diff = code.charCodeAt(i + 1) - code.charCodeAt(i);
    if (diff !== 1 && diff !== -1) {
      isSequence = false;
    }
  }

  return !(isSamePin || isSequence);
}

export function validateKTP(ktpNum) {
  const pattern =
    /^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\d{2}\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\d{2}\d{4}$/;
  return pattern.test(ktpNum);
}

export function filterRejected(payload, isTransactional) {
  const approvedList = [];
  const pendingList = [];
  const rejectedList = [];
  payload.forEach(e => {
    if (e.status === PHONE_ENROLLMENT_STATUS.APPROVED) approvedList.push(e);
    if (e.status === PHONE_ENROLLMENT_STATUS.PENDING) pendingList.push(e);
    if (!isTransactional && e.status === PHONE_ENROLLMENT_STATUS.REJECTED) {
      rejectedList.push(e);
    }
  });
  return approvedList.concat(pendingList, rejectedList);
}

export function onClickSecondaryPhonNoBottomSheet(
  setInfoModal,
  infoModal,
  deletePhoneNumber,
  currentSelectedBankId,
  BOTTONSHEET_PHONE_ENROLLMENT,
) {
  setInfoModal({ ...infoModal, visible: false });
  if (infoModal.errorCode === BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE) {
    deletePhoneNumber(currentSelectedBankId);
  }
}

export function resetStackToCustomScreen(navigation, params, screenNameToReset) {
  return navigation.dispatch(state => {
    const { routes } = state;
    const indexOfPageToReset = routes.indexOf(routes.filter(item => item.name === screenNameToReset)[0]);
    const splitRoutes = routes.slice(0, indexOfPageToReset + 1);
    return CommonActions.reset({
      ...state,
      routes: splitRoutes,
      index: splitRoutes.length - 1,
      ...params,
    });
  });
}

export function addPhnoCompleteCallBack(navigation, toast, data, t, params) {
  if (data.status === 'PENDING') {
    toast.show({
      render: () => <ToastWarning data={t('addPhone.phonePending')} />,
      placement: 'top',
      duration: 5000,
    });
  }
  if (data.status === 'APPROVED') {
    toast.show({
      render: () => <ToastSuccess data={t('addPhone.phoneApproved')} />,
      placement: 'top',
      duration: 5000,
    });
  }
  const state = navigation.getState();
  const { routes } = navigation.getState();
  const enrolledPhonesExists = routes.filter(item => item.name === ScreenNames.ENROLLED_PHONES);
  if (enrolledPhonesExists.length > 0) {
    return resetStackToCustomScreen(navigation, params, ScreenNames.ENROLLED_PHONES);
  }
  const newRoute = [];
  newRoute.push(routes[0]);
  newRoute.push({ name: ScreenNames.ENROLLED_PHONES, params: 'TULLA' });
  return navigation.dispatch(
    CommonActions.reset({
      ...state,
      routes: newRoute,
      index: 1,
      ...params,
    }),
  );
}

export function onErrPhoneNoCallbak(BOTTONSHEET_PHONE_ENROLLMENT, BOTTONSHEET_PHONE_CONTENT, setInfoModal, logEvent) {
  return err => {
    // GG-PHONE-02
    if (err.message === 'PHONE_ALREADY_EXISTS') {
      setInfoModal(prevState => ({
        ...prevState,
        data: returnBottomSheetContent(
          BOTTONSHEET_PHONE_ENROLLMENT.DUPLICATE_ENTRY,
          BOTTONSHEET_PHONE_CONTENT[BOTTONSHEET_PHONE_ENROLLMENT.DUPLICATE_ENTRY],
        ),
        errorCode: BOTTONSHEET_PHONE_ENROLLMENT.DUPLICATE_ENTRY,
        visible: true,
      }));
      logEvent('number_duplicated');
    }

    if (err.message === 'REGISTERED_PHONE_LIMIT') {
      setInfoModal(prevState => ({
        ...prevState,
        data: returnBottomSheetContent(
          BOTTONSHEET_PHONE_ENROLLMENT.MAX_LIMIT,
          BOTTONSHEET_PHONE_CONTENT[BOTTONSHEET_PHONE_ENROLLMENT.MAX_LIMIT],
        ),
        errorCode: BOTTONSHEET_PHONE_ENROLLMENT.MAX_LIMIT,
        visible: true,
      }));

      logEvent('max_reached');
    }
  };
}

export const getReferralEncryption = code =>
  code
    .split('')
    .map(item => item.charCodeAt(0))
    .join('');

export function changeAmountWithFormatting(currencySeperatorRegEx, setDisableWithdrawButton, setInputAmountValue) {
  return val => {
    const converted = val.replace(new RegExp(currencySeperatorRegEx, 'g'), '');

    if (isValidNumberWithDot(converted)) {
      setDisableWithdrawButton(false);
      setInputAmountValue(converted);
    } else {
      setDisableWithdrawButton(true);
    }
  };
}

export function shouldShowRightArrowOrTickForPhone(item, bpPhone) {
  if (item.mobileNumber === bpPhone.mobileNumber) {
    return <Ionicons name="checkmark" size={18} color={colors.primary.carnation} />;
  }
  return <RightArrow />;
}

export function showStatusViewForPhone(item, t, isTransact, bpPhone) {
  if (item?.status?.toUpperCase() === 'PENDING') {
    return (
      <View style={appStyles.pendingContainer}>
        <Text variant="xsm-bold" style={appStyles.pendingText}>
          {t('phoneTransfer.phoneVerify')}
        </Text>
      </View>
    );
  }
  if (item?.status?.toUpperCase() === 'APPROVED' && isTransact) {
    return shouldShowRightArrowOrTickForPhone(item, bpPhone);
  }
  if (item?.status?.toUpperCase() === 'REJECTED') {
    return (
      <TouchableOpacity>
        <View style={appStyles.rejectedContainer}>
          <View style={appStyles.rejectedTextContainer}>
            <Text variant="xsm-bold" style={appStyles.rejectedText}>
              {t('phoneTransfer.phoneRejected')}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
  return null;
}

export function shouldShowRightArrowOrTickForBank(item, bank) {
  if (bank && item.accountNumber === bank.accountNumber) {
    return <Ionicons name="checkmark" size={18} color={colors.primary.carnation} />;
  }
  return <RightArrow />;
}

export function showStatusViewForBank(item, t, isTransact, bankForTransfer) {
  if (item?.status?.toUpperCase() === 'PENDING') {
    return (
      <View style={appStyles.pendingContainer}>
        <Text variant="xsm-bold" style={appStyles.pendingText}>
          {t('phoneTransfer.phoneVerify')}
        </Text>
      </View>
    );
  }
  if (item?.status?.toUpperCase() === 'APPROVED' && isTransact) {
    return shouldShowRightArrowOrTickForBank(item, bankForTransfer);
  }
  if (item?.status?.toUpperCase() === 'REJECTED') {
    return (
      <TouchableOpacity>
        <View style={appStyles.rejectedContainer}>
          <View style={appStyles.rejectedTextContainer}>
            <Text variant="xsm-bold" style={appStyles.rejectedText}>
              {t('phoneTransfer.phoneRejected')}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
  return null;
}

function getNpmVersion() {
  // This function needs to be defined to return a version string, e.g., "1.0.3"
  return packageJson.version; // Example version, replace with real version fetching logic
}

export function getVersionCode() {
  const [major, minor, patch] = getNpmVersion().split('.');
  const base = 1000000000;
  const versionMajorTransposed = parseInt(major, 10) * 10000;
  const versionMinorTransposed = parseInt(minor, 10) * 100;
  const versionPatchTransposed = parseInt(patch, 10) * 1;
  return base + (versionMajorTransposed + versionMinorTransposed + versionPatchTransposed);
}

export function getReleaseName() {
  const buildVersionCode = getVersionCode();
  return `${config.ENVIRONMENT}@${buildVersionCode}`;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const getAppUpdateStatus = (list, currentSemverSplit) => {
  let mandatory = 0;
  let nonMandatory = 0;
  list.forEach(e => {
    const splitSemver = e.version_number.split('.');

    for (let index = 0; index < splitSemver.length; index += 1) {
      const a = parseInt(splitSemver[index], 10);
      let b = null;
      if (currentSemverSplit[index] && !isNaN(currentSemverSplit[index])) {
        b = parseInt(currentSemverSplit[index], 10);
      }

      if (a > b) {
        if (e.force_update) {
          mandatory += 1;
        } else if (e.recommended_update) {
          nonMandatory += 1;
        }
      } else if (a < b && (index === 0 || index === 1)) {
        break;
      }
    }
  });

  if (mandatory > 0) {
    return UPDATE_TYPE.MANDATORY;
  }
  if (nonMandatory > 0) {
    return UPDATE_TYPE.OPTIONAL;
  }
  return UPDATE_TYPE.NO_UPDATE;
};

export const PLAYSTORE_URL = 'https://play.google.com/store/apps/details?id=com.gajigesanative&hl=en';

export function onRedirectionHandler(successPageBanners, navigation, eventName, logParams) {
  return () => {
    const { redirectionType = '', redirectTo = '' } = successPageBanners[0];
    AmplitudeHelper.logEvent(eventName, logParams);

    if (redirectionType === REDIRECTION_TYPES.SCREEN_NAMES) return resetToNavStack(navigation, redirectTo);
    if (redirectionType === REDIRECTION_TYPES.EXTERNAL_LINK) return Linking.openURL(redirectTo);
    if (redirectionType === REDIRECTION_TYPES.DEEPLINK) {
      const result = Linking.canOpenURL(redirectTo);
      if (result) return Linking.openURL(redirectTo);
    }
    return null;
  };
}

export const getAmplitudeYesNo = value => {
  if (value) return 'yes';
  return 'no';
};

export const getAmplitudeKeyValue = (refEvent, arrObj = []) => {
  if (!arrObj) return {};
  const filteredEventsObj = arrObj.filter(eo => eo?.event?.includes(refEvent));
  const solution = {};
  filteredEventsObj.forEach(item => {
    solution[item.key] = item.value;
  });
  return solution;
};
