import { fetchEmployeeRegList } from '@api/useQueryCalls';
import PointEarned from '@atoms/UserProfile/PointEarned';
import { Fontisto, Ionicons } from '@expo/vector-icons';
import { shouldShowError } from '@organisms/HomeTab/homeUtils';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import generateCodeShortLink from '@screens/GGReferralPage/referralPageUtils';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Center, Skeleton, Text, VStack, useToast } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Pressable, TextInput, StatusBar, ScrollView, Platform } from 'react-native';
import { SceneMap, TabBar, TabView } from 'react-native-tab-view';
import {
  logEmployeeList,
  logInputChange,
  logInviteStatusClicked,
  logPicPage,
  logTermsPress,
} from './analyticsPIcListing';
import { getReferralEncryption, calculateTotalPoints } from './picListingUtils';
import styles from './style';
import NamesListing from '../NameListing';

const renderScene = SceneMap({
  daftarTeman: NamesListing,
  statusAjakan: NamesListing,
});

export const renderTabBar = props => (
  <TabBar
    {...props}
    testID="tabBar"
    getLabelText={({ route }) => route.title}
    style={styles.tabBarStyle}
    activeTintColor={colors.neutral_cotton}
    inactiveTintColor={colors.secondary_orchid}
    tabStyle={styles.tabStyle}
    labelStyle={styles.labelStyle}
    inactiveColor={styles.inActiveTabbarStyle}
    indicatorStyle={styles.indicatorStyle}
    indicatorContainerStyle={styles.indicatorContainerStyle}
  />
);

export const onPageChangeHandler = (idx, setIndex, isPIC) => {
  if (idx === 0) logEmployeeList(isPIC);

  if (idx === 1) logInviteStatusClicked(isPIC);

  setIndex(idx);
};

export function shouldRenderClose(show, setSearchText) {
  if (show)
    return (
      <Pressable testID="closeSearchBtn" onPress={() => setSearchText('')}>
        <Ionicons name="close-circle" color={colors.neutral_charcoal} size={scale(16)} style={styles.closeStyle} />
      </Pressable>
    );
  return null;
}

export const SkeletonLoader = () => (
  <Center w="100%">
    <VStack
      w="100%"
      borderWidth="1"
      space={8}
      overflow="hidden"
      rounded="md"
      _dark={{
        borderColor: 'coolGray.500',
      }}
      _light={{
        borderColor: 'coolGray.200',
      }}>
      <Skeleton h="40" />
      <Skeleton.Text px="4" />
      <Skeleton.Text px="4" />
      <Skeleton.Text px="4" />
      <Skeleton.Text px="4" />
      <Skeleton.Text px="4" />
      <Skeleton.Text px="4" />
    </VStack>
  </Center>
);

const PicLanding = () => {
  const [index, setIndex] = useState(0);

  const {
    setSearchText,
    setPicReferralEmployees,
    employeeId,
    searchText,
    resetPicReferral,
    setInitialData,
    employerId,
  } = getStore();

  const referralCode = getReferralEncryption(employeeId.toString());

  const navigation = useNavigation();
  const [accumulatedTotalPoin, setAccumulatedTotalPoin] = useState(0);
  const { t } = useTranslation('common');

  const tabOneMeta = { key: 'daftarTeman', title: t('userProfile.titleOne'), shortLink: '' };
  const tabTwoMeta = {
    key: 'statusAjakan',
    title: t('userProfile.titleTwo'),
    shortLink: '',
  };

  const [routes, setRoutes] = useState([tabOneMeta, tabTwoMeta]);
  const toast = useToast('common');

  const { employeeProfile } = getStore();

  const isPIC = employeeProfile?.influencerRole && employeeProfile?.influencerRole === 'PIC';

  const {
    isLoading: empRegLoader,
    isError: isEmpRegError,
    error: empRegError,
    refetch: fetchEmployeeRegListCall,
  } = fetchEmployeeRegList({ employeeId }, data => {
    setAccumulatedTotalPoin(calculateTotalPoints(data));
    setPicReferralEmployees(data?.employeesToRefer ?? [], data?.employeesReferred ?? []);
  });

  useEffect(() => {
    shouldShowError(isEmpRegError, empRegError, toast, t, navigation, setInitialData);
  }, [isEmpRegError, empRegError]);

  useEffect(() => {
    logPicPage(isPIC);
  }, []);

  const getShareLink = async () => {
    try {
      const campaign_source = `pic_zone_emplr_${employerId}`;
      const campaign_medium = `pic_zone_emplr_${employerId}`;
      const campaign_name = `pic_zone_emplr_${employerId}`;
      const shortLink = await generateCodeShortLink(referralCode, campaign_source, campaign_medium, campaign_name);
      setRoutes([
        { ...tabOneMeta, shortLink },
        { ...tabTwoMeta, shortLink },
      ]);
    } catch (e) {
      //   console.log(e)
    }
  };

  const isFocused = useIsFocused();

  useEffect(() => () => resetPicReferral(), []);

  useEffect(() => {
    if (Platform.OS === 'android') getShareLink();
  }, []);

  useEffect(() => {
    if (isFocused) fetchEmployeeRegListCall();
  }, [isFocused]);

  useEffect(() => {
    setSearchText('');
  }, [setSearchText]);

  const onTermsPress = () => {
    logTermsPress(isPIC);
    navigation.navigate('PicTerms');
  };

  const onInputEndHandler = () => {
    logInputChange(isPIC);
  };

  if (empRegLoader) return <SkeletonLoader />;

  return (
    <ScrollView contentContainerStyle={styles.scrollableContainerStyle} style={styles.mainContentStyle}>
      <StatusBar translucent={false} backgroundColor={colors.secondary.orchid} />

      <PointEarned accumulatedTotalPoin={accumulatedTotalPoin} t={t} isPIC={isPIC} />
      <Text variant="sm-normal" style={styles.termsParentLink}>
        {t('userProfile.termsText')}
        <Text variant="sm-bold" testID="linkTerms" onPress={onTermsPress} style={styles.linkStyle}>
          {' '}
          {t('userProfile.linkTerms')}
        </Text>
      </Text>
      <View style={styles.inputTextContainer}>
        <TextInput
          value={searchText}
          testID="test-id"
          style={styles.inputSearch}
          onBlur={onInputEndHandler}
          onChangeText={tx => setSearchText(tx)}
          placeholder={t('userProfile.textInputPlaceHolder')}
          placeholderTextColor={colors.neutral.darkMist}
        />
        {shouldRenderClose(searchText?.length > 0, setSearchText)}
        <Fontisto name="search" color={colors.neutral_charcoal} size={scale(16)} />
      </View>
      <View style={styles.tabViewContainer}>
        <TabView
          lazy
          testID="tabViewPic"
          renderTabBar={renderTabBar}
          navigationState={{ index, routes }}
          renderScene={renderScene}
          onIndexChange={idx => onPageChangeHandler(idx, setIndex, isPIC)}
        />
      </View>
    </ScrollView>
  );
};

export default PicLanding;
