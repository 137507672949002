import BankFlatListData from '@atoms/BankFlatListData';
import SkeletonComponent from '@atoms/SkeletonComponent';
import { useRoute } from '@react-navigation/native';
import PropTypes from 'prop-types';
import React from 'react';

function BankList(props) {
  const { fromTransfer, setBankForTransfer, bankList } = props;
  const route = useRoute();
  const { params } = route;
  const { source } = params;
  if (fromTransfer && bankList && bankList.length === 0) {
    return <SkeletonComponent />;
  }
  if (bankList.length > 0) {
    return (
      <BankFlatListData
        bankList={bankList}
        fromTransfer={fromTransfer}
        setBankForTransfer={setBankForTransfer}
        source={source}
      />
    );
  }
  return null;
}

BankList.propTypes = {
  bankList: PropTypes.arrayOf({}),
  fromTransfer: PropTypes.bool,
  setBankForTransfer: PropTypes.func,
};
BankList.defaultProps = {
  bankList: [],
  fromTransfer: false,
  setBankForTransfer: () => {},
};
export default BankList;
