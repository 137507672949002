import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import config from '@config';
import * as Sentry from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import AmplitudeHelper from '@utils/analytics';
// import secureStore from '@utils/secureStore';
import tokenManager from '@utils/secureStore/tokenManager';

// Client provider for api(s)
export const queryClient = new QueryClient();

const authLink = setContext(async (_, { headers }) => {
  const token = tokenManager.getAccessToken();
  // await secureStore.getItemAsync('accessToken');
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation, response }) => {
  AmplitudeHelper.logEvent('gql_error_request_op', { request: operation });
  Sentry.addBreadcrumb({
    category: 'graphql',
    message: 'Request',
    level: 'info',
    data: {
      request: operation,
    },
  });
  AmplitudeHelper.logEvent('gql_error_response_op', { response });
  Sentry.addBreadcrumb({
    category: 'graphql',
    message: 'Response',
    level: 'info',
    data: {
      response,
    },
  });

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      AmplitudeHelper.logEvent('gql_error', {
        gqlError: {
          message: `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          data: {
            operation: operation.operationName,
            locations,
            path,
          },
        },
      });
      Sentry.addBreadcrumb({
        category: 'graphql',
        message: `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        level: 'info',
        data: {
          operation: operation.operationName,
          locations,
          path,
        },
      });
    });
  }

  if (networkError) {
    AmplitudeHelper.logEvent('gql_error_network_error', {
      netWorkError: {
        message: `[Network error]: ${networkError}`,
        data: {
          operation: operation.operationName,
        },
      },
    });
    Sentry.addBreadcrumb({
      category: 'graphql',
      message: `[Network error]: ${networkError}`,
      level: 'info',
      data: {
        operation: operation.operationName,
      },
    });
  }
});

const httpLink = new HttpLink({
  uri: config.GRAPHQL_URL,
});

// GraphQL Provider
export const gqClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});
