import ToastSuccess from '@atoms/ToastSuccess';
import dynamicLinks from '@react-native-firebase/dynamic-links';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { getReferralEncryption } from '@utils/utils';
import * as Clipboard from 'expo-clipboard';
import i18n from 'i18next';
import React from 'react';
import { Platform, Share } from 'react-native';

async function buildLink(code, campaign_source, campaign_medium, campaign_name) {
  const utmParameters = `utm_source=${campaign_source}&utm_medium=${campaign_medium}&utm_campaign=${campaign_name}`;

  return dynamicLinks().buildShortLink(
    {
      link: `https://gajigesanative.page.link/referral/${code}?${utmParameters}`,
      // domainUriPrefix is created in your Firebase console
      domainUriPrefix: 'https://gajigesanative.page.link',
      ios: {
        bundleId: 'org.reactjs.native.example.GajiGesaNative',
        customScheme: 'com.googleusercontent.apps.959826625148-n033p2bufr42l3nr9aki29hql01r4cn1',
        fallbackUrl: 'https://gg-employee.gajigesa-infra.com/',
      },
      android: {
        packageName: 'com.gajigesanative',
      },
      navigation: {
        forcedRedirectEnabled: true,
      },
      analytics: {
        source: campaign_source,
        medium: campaign_medium,
        campaign: campaign_name,
      },
    },
    'SHORT',
  );
}

export default buildLink;

export function onEarnedReferralComplete(setEarnedPoints) {
  return data => {
    setEarnedPoints(data?.account_transaction_aggregate?.aggregate?.sum?.transTotalAmount || 0);
  };
}

export function getSharingText(referralCode, shortLink) {
  const { t } = i18n;
  if (Platform.OS === 'web') return referralCode;
  return (
    t('common:referral.textCopiedToClipboardPart1') +
    referralCode +
    t('common:referral.textCopiedToClipboardPart2') +
    shortLink +
    t('common:referral.txtTnCPoins')
  );
}

export async function shareApiHandler(referralCode, shortLink) {
  try {
    const result = await Share.share({
      message: getSharingText(referralCode, shortLink),
    });
    if (result.action === Share.sharedAction) AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Referral.Select.name);
  } catch (e) {
    // share api falied
  }
}

export function shareApiGeneric(employeeId, shortLink) {
  const referralCode = getReferralEncryption(employeeId.toString());
  shareApiHandler(referralCode, shortLink);
}

export function referralPageHandlers(setCollapsedUsage, collapsedUsage, referralCode, toast, t) {
  const onPressDropDown = () => {
    setCollapsedUsage(!collapsedUsage);
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Referral.Dropdown.name, {
      [AMPLITUDE_CONSTANTS.Referral.Dropdown.attributes.action]: collapsedUsage ? 'collapse' : 'expand',
    });
  };

  const onCopyToClipBoard = async shortLink => {
    try {
      const clipBoardValue = await Clipboard.setStringAsync(getSharingText(referralCode, shortLink));
      if (clipBoardValue) {
        toast.show({
          render: () => <ToastSuccess data={t('referral.codeCopiedSuccessfully')} />,
          placement: 'top',
        });
        AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Referral.CopySuccess.name, {
          [AMPLITUDE_CONSTANTS.Referral.CopySuccess.attributes.referral_code]: referralCode || null,
        });
      }
    } catch (e) {
      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Referral.CopyFailed.name, {
        [AMPLITUDE_CONSTANTS.Referral.CopyFailed.attributes.referral_code]: referralCode || null,
        [AMPLITUDE_CONSTANTS.Referral.CopyFailed.attributes.failure_message]: 'system_error',
      });
    }
  };

  const shareShortLinkCallBack = async shortLink => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Referral.Share.name, {
      [AMPLITUDE_CONSTANTS.Referral.Share.attributes.referral_code]: referralCode || null,
    });
    await shareApiHandler(referralCode, shortLink);
  };
  return { shareShortLinkCallBack, onPressDropDown, onCopyToClipBoard };
}
