import ReasonsSwitch from '@atoms/ReasonsSwtich';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { View } from 'native-base';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { KeyboardAvoidingView, Platform } from 'react-native';
import shouldShowCustomReasons from './reasonsListUtils';
import styles from './styles';

export function onUserSwipeHandler(source, validatedReferralCode, amplitudeAccountName, setActivePage) {
  return index => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Withdrawal.userSwipesLeftOrRight.name, {
      [AMPLITUDE_CONSTANTS.Withdrawal.userSwipesLeftOrRight.attributes.source]: source,
      [AMPLITUDE_CONSTANTS.Withdrawal.userSwipesLeftOrRight.attributes.referral_code]: validatedReferralCode,
      dynamic_account_name: amplitudeAccountName,
    });
    setActivePage(index);
  };
}

function ReasonsList(props) {
  const {
    reasons,
    setReasonsDisplay,
    setDisableContinue,
    setCustomReasonText,
    isEditReasons,
    source,
    transaction_category_id,
  } = props;
  const reasonsCarousel = useRef();

  return (
    <View ref={reasonsCarousel} style={styles.reasonParent}>
      <View style={styles.reasonGrid}>
        {reasons.map(reasonItem => (
          <ReasonsSwitch
            key={reasonItem.id}
            reason={reasonItem}
            allReasons={reasons}
            setSelectedReason={setReasonsDisplay}
            setDisableContinue={setDisableContinue}
            source={source}
            transaction_category_id={transaction_category_id}
          />
        ))}
      </View>
      <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        {shouldShowCustomReasons(isEditReasons, setCustomReasonText, source)}
      </KeyboardAvoidingView>
    </View>
  );
}

ReasonsList.propTypes = {
  source: PropTypes.string,
  transaction_category_id: PropTypes.string,
  reasons: PropTypes.arrayOf({}),
  setReasonsDisplay: PropTypes.func,
  setDisableContinue: PropTypes.func,
  setCustomReasonText: PropTypes.func,
  isEditReasons: PropTypes.bool,
};
ReasonsList.defaultProps = {
  source: '',
  transaction_category_id: '',
  reasons: [],
  setReasonsDisplay: () => {},
  setDisableContinue: () => {},
  setCustomReasonText: () => {},
  isEditReasons: false,
};
export default ReasonsList;
