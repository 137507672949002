import config from '@config';
import apiConfig from './apiConfig';
import invokeApi from './network';

/**
 *
 * @param {object} payload contains otpType and mobileNumber
 * @returns api promise to be resolved by react query
 */
export const apiLogin = payload => {
  const { otpType, mobileNumber } = payload;
  return invokeApi(
    apiConfig.login,
    config.API_URL,
    {
      queryProps: {
        otpType,
        mobileNumber,
      },
    },
    null,
    null,
    null,
  );
};

/**
 *
 * @param {object} payload
 * @param {string} token
 * @returns api promise to be resolved by react query
 */
export const apiOTP = (payload, token) =>
  invokeApi(apiConfig.otp, config.API_URL, null, { 'x-otp-token': token }, payload, null);

/**
 *
 * @returns api promise to be resolved by react query
 */
export const apiLogout = () => invokeApi(apiConfig.logout, config.API_URL, null, null, null, null);

export const apiMe = () => invokeApi(apiConfig.me, config.API_URL, null, null, null, null);

export const apiReasons = payload => {
  const {
    // limit, skip,
    accountNumber,
    reasonType,
  } = payload;
  return invokeApi(
    apiConfig.reasons,
    config.API_URL,
    {
      queryProps: {
        // limit,
        // skip,
        reasonType,
      },
      mixedProps: {
        accountNumber,
      },
    },
    null,
    null,
    null,
  );
};

export const apiPaymentCycles = payload => {
  const { limit, skip } = payload;
  return invokeApi(
    apiConfig.paymentCycles,
    config.API_URL,
    {
      queryProps: {
        limit,
        skip,
      },
    },
    null,
    null,
    null,
  );
};

export const apiTransactionSummary = payload => {
  const { accountNumber, paymentCycleId } = payload;

  return invokeApi(
    apiConfig.transactionSummary,
    config.API_URL,
    {
      queryProps: {
        paymentCycleId,
      },
      mixedProps: {
        accountNumber,
      },
    },
    null,
    null,
    null,
  );
};

export const apiTransactionHistory = payload => {
  const { accountNumber, limit, skip, paymentCycleId } = payload;

  return invokeApi(
    apiConfig.transactionsHistory,
    config.API_URL,
    {
      mixedProps: {
        accountNumber,
      },
    },
    null,
    {
      limit,
      skip,
      paymentCycleId,
    },
    null,
  );
};

export const apiMarketingBanners = () => invokeApi(apiConfig.marketingBanner, config.API_URL, null, null, null, null);

export const apiBillPayment = payload => {
  const { accountTypeId } = payload;
  return invokeApi(apiConfig.billPayment, config.API_URL, { queryProps: { accountTypeId } }, null, null, null);
};

export const apiTopProducts = () => invokeApi(apiConfig.topProducts, config.API_URL, null, null, null, null);

export const apiReferralValidation = code =>
  invokeApi(apiConfig.referralValidation, config.API_URL, { queryProps: { code } }, null, null, null);

export const apiSubProductsOrNominals = payload => {
  const { billAccountNumber, accountTypeId, productId } = payload;
  return invokeApi(
    apiConfig.subProductsOrNominals,
    config.API_URL,
    { queryProps: { billAccountNumber, accountTypeId }, mixedProps: { productId } },
    null,
    payload,
    null,
  );
};

export const apiNumberHistory = payload => {
  const { productPrefix } = payload;
  return invokeApi(apiConfig.numberHistory, config.API_URL, { queryProps: { productPrefix } }, null, null, null);
};

export const apiBPRequest = payload => invokeApi(apiConfig.bpRequest, config.API_URL, null, null, payload, null);

export const apiBPConfirm = payload => invokeApi(apiConfig.bpConfirm, config.API_URL, null, null, payload, null);

export const apiBanksEnrolled = () => invokeApi(apiConfig.banksEnrolled, config.API_URL, null, null, null, null);

export const apiWithdrawalRequest = payload =>
  invokeApi(apiConfig.withdrawRequest, config.API_URL, null, null, payload, null);

export const apiWithdrawalConfirm = payload =>
  invokeApi(apiConfig.withdrawConfirm, config.API_URL, null, null, payload, null);

export const apiToUploadImage = payload =>
  invokeApi(apiConfig.imageUpload, config.API_URL, null, { 'Content-Type': 'multipart/form-data' }, payload, null);

export const apiToDeleteImage = payload =>
  invokeApi(
    apiConfig.deletImage,
    config.API_URL,
    {
      mixedProps: { documentId: payload.documentId },
    },
    null,
    null,
    null,
  );

export const apiToEditTransactions = payload => {
  const { description, transactionReasonId, transactionHistoryId, accountNumber } = payload;

  return invokeApi(
    apiConfig.updateTransaction,
    config.API_URL,
    {
      mixedProps: { transactionHistoryId },
    },
    null,
    { description, transactionReasonId, accountNumber },
    null,
  );
};

export const apiForAutoDisburse = payload => {
  const { employeeId } = payload;
  return invokeApi(
    apiConfig.autoDisburseDetails,
    config.API_URL,
    {
      mixedProps: {
        employeeId,
      },
    },
    null,
    null,
    null,
  );
};

export const apiForPromocode = payload => {
  const { employeeId } = payload;
  return invokeApi(
    apiConfig.getPromoCode,
    config.API_URL,
    {
      mixedProps: {
        employeeId,
      },
    },
    null,
    null,
    null,
  );
};

export const apiSecurityPinSimpleRegistration = payload =>
  invokeApi(apiConfig.securityPinSimpleRegistration, config.API_URL, null, null, { securityPin: payload }, null);

export const apiSecurityPinVerify = payload =>
  invokeApi(apiConfig.securityPinVerify, config.API_URL, null, null, { securityPin: payload }, null);

export const apiForPicEmployeeReferralList = payload => {
  const { employeeId } = payload;
  return invokeApi(
    apiConfig.picEmployeeReferralList,
    config.API_URL,
    {
      mixedProps: {
        employeeId,
      },
    },
    null,
    null,
    null,
  );
};

export const apiForVerifyKTP = payload => invokeApi(apiConfig.verifyKTP, config.API_URL, null, null, payload, null);

export const apiForGoldRequest = payload => invokeApi(apiConfig.goldRequest, config.API_URL, null, null, payload, null);

export const apiForTxnGold = payload => {
  const { txnID } = payload;
  return invokeApi(
    apiConfig.goldTxnRequest,
    config.API_URL,
    {
      mixedProps: {
        invoiceNo: txnID,
      },
    },
    null,
    null,
    null,
  );
};

export const apiForGoldConfirmation = payload =>
  invokeApi(apiConfig.goldConfirmation, config.API_URL, null, null, payload, null);

export const apiForGoldTransactionCancel = payload =>
  invokeApi(
    apiConfig.cancelGoldTransaction,
    config.API_URL,
    {
      mixedProps: {
        txnID: payload.txnID,
      },
    },
    null,
    null,
    null,
  );

export const apiForShareEmployeeReferral = payload =>
  invokeApi(apiConfig.employerShareReferral, config.API_URL, null, null, payload, null);

export const apiProfile = () => invokeApi(apiConfig.profile, config.API_URL, null, null, null, null);

export const apiBankList = payload => {
  const { limit, skip } = payload;

  return invokeApi(apiConfig.allBanks, config.API_URL, { queryProps: { limit, skip } }, null, null, null);
};

export const addEmployeeBeneficiary = payload =>
  invokeApi(apiConfig.addBank, config.API_URL, null, null, payload, null);

export const deleteEmployeeBeneficiary = payload =>
  invokeApi(
    apiConfig.deleteBank,
    config.API_URL,
    {
      mixedProps: { beneficiaryId: payload },
    },
    null,
    payload,
    null,
  );

export const getLoan = () => invokeApi(apiConfig.loan, config.API_URL, null, null, null, null);

export const markUserNotified = payload =>
  invokeApi(
    apiConfig.userNotified,
    config.API_URL,
    {
      mixedProps: { applicationId: payload.applicationId },
    },
    null,
    null,
    null,
  );

export const getLoanProductsList = () => invokeApi(apiConfig.loanProducts, config.API_URL, null, null, null, null);

export const loanApply = payload => invokeApi(apiConfig.loanApply, config.API_URL, null, null, payload, null);

export const apiGetPhoneList = () => invokeApi(apiConfig.phoneNumberList, config.API_URL, null, null, null, null);

export const addPhoneForUser = payload => invokeApi(apiConfig.addPhone, config.API_URL, null, null, payload, null);

export const deletePhoneNumber = payload =>
  invokeApi(apiConfig.deletePhone, config.API_URL, { mixedProps: { phoneNumberId: payload } }, null, payload, null);

export const getSalaryInfo = payload => invokeApi(apiConfig.salaryInfo, config.API_URL, null, null, payload, null);

export const getLogs = () => invokeApi(apiConfig.logInfo, config.API_URL, null, null, null, null);

export const checkTimeout = () => invokeApi(apiConfig.testTimeOut, config.API_URL, null, null, null, null);
