import BankRow from '@atoms/BankRow';
import appStyles from '@root/appStyles';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { View, Divider } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FlatList } from 'react-native';

const itemSeperator = () => <Divider color={colors.neutral.mist} height="1px" />;

function BankFlatListData(props) {
  const { bankList, fromTransfer, setBankForTransfer, source } = props;
  useEffect(() => {
    const e = AMPLITUDE_CONSTANTS.bankRegistration.bank_page;
    const { name, attributes } = e;
    AmplitudeHelper.logEvent(name, {
      [attributes.source]: source,
    });
  }, []);
  const bankListRow = ({ item }) => (
    <BankRow item={item} fromTransfer={fromTransfer} setBankForTransfer={setBankForTransfer} source={source} />
  );
  return (
    <View style={{}}>
      <View style={appStyles.marginHorizontal(16)}>
        <FlatList
          testID="fltLstBanks"
          style={appStyles.marginVertical(8)}
          ItemSeparatorComponent={itemSeperator}
          data={bankList}
          renderItem={bankListRow}
          showsVerticalScrollIndicator={false}
        />
      </View>
    </View>
  );
}

BankFlatListData.propTypes = {
  bankList: PropTypes.arrayOf(PropTypes.shape({})),

  setBankForTransfer: PropTypes.func,
  fromTransfer: PropTypes.bool,
  source: PropTypes.string,
};

BankFlatListData.defaultProps = {
  bankList: [{}],
  setBankForTransfer: () => {},
  fromTransfer: true,
  source: '',
};

export default BankFlatListData;
