import logEyeIconClicked from '@atoms/BankRow/analyticsBankRow';
import { Ionicons } from '@expo/vector-icons';
import {
  getMaskedOrUnmaskedData,
  setMaskIconValue,
} from '@molecules/ProfileDefaultUserBank/profileDefaultUserBankUtils';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { showStatusViewForBank } from '@utils/utils';
import { View, Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function onPressBankIcon(source, maskData, item, setMaskIcon, setMaskData) {
  return () => {
    logEyeIconClicked(source, maskData, item);
    setMaskIcon(setMaskIconValue(maskData));
    setMaskData(!maskData);
  };
}

function BankRowStatusPending(props) {
  const { t } = useTranslation('common');
  const { item, maskData, maskIcon, setMaskIcon, setMaskData, fromTransfer, source } = props;
  const { bankForTransfer } = getStore();
  return (
    <View style={appStyles.marginVertical(8)}>
      <Text variant="md-bold" style={{ color: fromTransfer ? colors.disabled.smoke : colors.neutral.charcoal }}>
        {item?.bank?.bankName}
      </Text>
      <View flexDirection="row" alignItems="center">
        <Text
          variant="sm-normal"
          style={
            fromTransfer
              ? [appStyles.flex1, { color: fromTransfer ? colors.disabled.smoke : colors.neutral.charcoal }]
              : null
          }>
          {fromTransfer ? item?.accountNumber : getMaskedOrUnmaskedData(maskData, item?.accountNumber)}
        </Text>
        {fromTransfer ? null : (
          <Ionicons
            name={maskIcon}
            size={18}
            color="black"
            testID="btnMaskUnmaskImage"
            style={[appStyles.marginLeft(8), appStyles.flex1]}
            onPress={onPressBankIcon(source, maskData, item, setMaskIcon, setMaskData)}
          />
        )}
        <View flex={1} alignItems="flex-end">
          {showStatusViewForBank(item, t, fromTransfer, bankForTransfer)}
        </View>
      </View>
      <View flexDirection="row" alignItems="center">
        {item?.nickname ? (
          <View flexDirection="row" alignItems="center">
            <Text
              color={colors.neutral.darkMist}
              variant="md-bold"
              style={{ color: fromTransfer ? colors.disabled.smoke : colors.neutral.charcoal }}>
              {item?.nickname}
            </Text>
            <View
              backgroundColor={fromTransfer ? colors.disabled.smoke : colors.neutral.darkMist}
              style={appStyles.dotStyles}
            />
          </View>
        ) : null}

        <Text
          color={colors.neutral.darkMist}
          variant="sm-normal"
          style={{ color: fromTransfer ? colors.disabled.smoke : colors.neutral.charcoal }}>
          {item?.beneficiaryName}
        </Text>
      </View>
    </View>
  );
}

BankRowStatusPending.propTypes = {
  maskData: PropTypes.bool,
  maskIcon: PropTypes.string,
  setMaskIcon: PropTypes.func,
  setMaskData: PropTypes.func,
  fromTransfer: PropTypes.bool,
  source: PropTypes.string,
  item: PropTypes.shape({
    id: PropTypes.number,
    accountNumber: PropTypes.string,
    beneficiaryName: PropTypes.string,
    nickname: PropTypes.string,
    status: PropTypes.string,
    bank: PropTypes.shape({
      bankName: PropTypes.string,
    }),
  }),
};
BankRowStatusPending.defaultProps = {
  maskData: false,
  maskIcon: '',
  setMaskIcon: () => {},
  setMaskData: () => {},
  fromTransfer: false,
  source: '',
  item: {
    id: 0,
    accountNumber: '',
    beneficiaryName: '',
    nickname: '',
    status: '',
    bank: {
      bankName: '',
    },
  },
};
export default BankRowStatusPending;
