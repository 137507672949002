import { BOTTONSHEET_KEYS } from '@root/constants';

export default function bankListBottomSheetHandlers(infoModal, setInfoModal, beneficiaryDeleteMutate) {
  const onPressPrimaryBottomSHeet = bankRowPressed => {
    if (infoModal.errorCode === BOTTONSHEET_KEYS.BANK_DELETE) {
      setInfoModal({ ...infoModal, visible: false });
      if (infoModal.errorCode === BOTTONSHEET_KEYS.BANK_DELETE) {
        // delete bank here
        beneficiaryDeleteMutate(bankRowPressed.id);
      }
    } else {
      setInfoModal({ ...infoModal, visible: false });
    }
  };
  const onPressSecondaryBottomSheet = () => {
    setInfoModal({ ...infoModal, visible: false });
  };
  const onCloseModalHandler = () => {
    setInfoModal({ ...infoModal, visible: false });
  };
  return { onPressPrimaryBottomSHeet, onPressSecondaryBottomSheet, onCloseModalHandler };
}
